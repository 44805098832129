import React from "react";
import CloseIcon from "../../assets/image/close-icon.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import sendMail from "../../services/collections/sendMail";

// Validation schema
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
});

const SharePopupSendMail = ({ id, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (formData) => {
    console.log("Form data:", formData);
    try {
      const res = await sendMail(id, formData.name, formData.email);
      console.log("API response:", res);
      handleClose(false);
      toast.success("Mail sent successfully!");
    } catch (error) {
      console.error("Error sending mail:", error);
      toast.error("Something went wrong, please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[rgba(34,34,34,0.05)] z-50">
      <div className="max-w-[458px] w-full p-[30px] bg-white relative z-10">
        <h6 className="text-lg md:text-2xl text-primary font-semibold text-center py-1">
          Send Mail
        </h6>
        <img
          src={CloseIcon}
          width="20"
          className="absolute top-4 right-4"
          alt="Close Icon"
          onClick={() => handleClose(false)}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-8">
            <div className="mb-[18px]">
              <label
                htmlFor="name"
                className="block mb-2 text-base font-semibold text-primary"
              >
                Enter Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Ex. Chris Evans"
                {...register("name")}
                required
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.name.message}
                </p>
              )}
            </div>
            <div className="">
              <label
                htmlFor="email"
                className="block text-base font-semibold text-primary"
              >
                Enter Email
              </label>
              <input
                type="email"
                id="email"
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Ex. chris@gmail.com"
                {...register("email")}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="mt-6 inline-flex justify-center items-center gap-2.5 px-9 py-4 text-lg font-semibold text-white text-center bg-secondary w-full"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SharePopupSendMail;
