const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Base URL of your API

export default async function Datafetch({
  method = "POST",
  query,
  variables,
  defaultParams = "graphql",
}) {
  const BearerToken = localStorage.getItem("token"); // access the token from your slice

  let HeaderObj = {
    "Content-Type": "application/json",
  };

  if (BearerToken) {
    HeaderObj = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${BearerToken}`,
    };
  }

  const requestOptions = {
    method,
    headers: HeaderObj,
  };

  if (method !== "GET" && (query || variables)) {
    requestOptions.body = JSON.stringify({ query, variables });
  }

  try {
    const url = `${API_BASE_URL}/${defaultParams}`;
    const result = await fetch(url, requestOptions);

    const responseBody = await result.json();

    if (!result.ok) {
      throw new Error(responseBody.message || "Something went wrong");
    }

    return {
      status: result.status,
      body: responseBody,
    };
  } catch (error) {
    return {
      status: 500,
      error: error instanceof Error ? error.message : "Something went wrong",
      body: null,
    };
  }
}
