// redux/Slices/shareSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createShare from "../../services/portfolio/createShare";

const initialState = {
  share: {
    data: null,
    loading: false,
    error: null,
  },
};

export const submitShare = createAsyncThunk(
  "share/submitShare",
  async ({ Name, Email, portfolio }, { rejectWithValue }) => {
    try {
      const response = await createShare({ Name, Email, portfolio });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const shareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitShare.pending, (state) => {
        state.share.loading = true;
        state.share.error = null;
      })
      .addCase(submitShare.fulfilled, (state, action) => {
        state.share.loading = false;
        state.share.data = action.payload;
      })
      .addCase(submitShare.rejected, (state, action) => {
        state.share.loading = false;
        state.share.error = action.payload;
      });
  },
});

export const selectShare = (state) => state.share.share;

export default shareSlice.reducer;
