import Datafetch from "./../../utils/Datafetch";

export default async function skillsConceptFetch() {
  const query = `query Concepts {
    concepts(pagination: { page: 1, pageSize: 1000 }) {
        data {
            id
            attributes {
                Name
                createdAt
                updatedAt
                publishedAt
                portfolios {
                    data {
                        id
                        attributes {
                            Title
                        }
                    }
                }
            }
        }
        meta {
            pagination {
                total
                page
                pageSize
                pageCount
            }
        }
    }
}
`;

  try {
    let storeFetchPara = {
      method: "POST",
      query,
    };

    // if (AccessToken !== undefined) {
    //   let BearerToken = `Bearer ${AccessToken}`;
    //   storeFetchPara = {
    //     method: "POST",
    //     query,
    //     BearerToken,
    //   };
    // }

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
    }

    if (body?.data !== null) {
      return body?.data?.concepts;
    }
  } catch (error) {
    console.log(`Error in testDataFetch ${error.message}`);
  }
}
