import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetIndustryListById from "../../services/Industry/getIndustryDetailsById";

const initialState = {
    getIndustryById: {
        data: [],
        loading: false,
        error: null
    }
};


export const fetchIndustryById = createAsyncThunk(
    "getIndustryDetail/fetchIndustryById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await GetIndustryListById(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getIndustryDetailsSlice = createSlice({
    name: "getIndustryDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIndustryById.pending, (state) => {
                state.getIndustryById.loading = true;
                state.getIndustryById.error = null;
            })
            .addCase(fetchIndustryById.fulfilled, (state, action) => {
                state.getIndustryById.loading = false;
                state.getIndustryById.data = action.payload;
            })
            .addCase(fetchIndustryById.rejected, (state, action) => {
                state.getIndustryById.loading = false;
                state.getIndustryById.error = action.payload;
            });
    },
});

export default getIndustryDetailsSlice.reducer;