import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AllProtfoliosList from "../../services/portfolio/allPortfoliosList";
import CaseStudyList from "../../services/portfolio/caseStudyList";
import ProtfolioList from "../../services/portfolio/portfolioList";

const initialState = {
    portfolios: {
        data: [],
        loading: false,
        error: null,
    }
};

export const fetchPortfolios = createAsyncThunk(
    "portfolios/fetchPortfolios",
    async ({ page = 1, activeTab,filters }, { rejectWithValue }) => {
        try {
            let response;
            if (activeTab === '0') {
                response = await AllProtfoliosList(page,filters);
            } else if (activeTab === '1') {
                response = await ProtfolioList(page,filters);
            } else {
                response = await CaseStudyList(page,filters);
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const portfoliosSlice = createSlice({
    name: "portfolios",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPortfolios.pending, (state) => {
                state.portfolios.loading = true;
                state.portfolios.error = null;
            })
            .addCase(fetchPortfolios.fulfilled, (state, action) => {
                state.portfolios.loading = false;
                state.portfolios.data = action.payload;
            })
            .addCase(fetchPortfolios.rejected, (state, action) => {
                state.portfolios.loading = false;
                state.portfolios.error = action.payload;
            })
    },
});

export const selectPortfolios = (state) => state.portfolios.portfolios;

export default portfoliosSlice.reducer;