import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createCollection from "../../services/collections/createCollection";
import GetCollectionByID from "../../services/collections/getCollectionByID";
import updateCollections from "../../services/collections/updateCollection";

const initialState = {
  new_created_collections_id: null,
  localCollectionItemList: [],
  collection: {
    data: [],
    loading: false,
    error: null,
  },
};

export const createCollectionApi = createAsyncThunk(
  "collectionDetails/createCollectionApi",
  async ({ user_id, portfolio_ids }, { rejectWithValue }) => {
    try {
      let response = await createCollection(user_id, portfolio_ids);
      let responseSecond = await GetCollectionByID(response?.data?.id);

      return response?.data?.id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCollectionApi = createAsyncThunk(
  "collection/update",
  async ({ collection_id, portfolio_ids }, { rejectWithValue }) => {
    try {
      console.log("##@334qqaerfzd_____________", portfolio_ids, collection_id);
      let response = await updateCollections(
        collection_id,
        portfolio_ids?.map((i) => String(i))
      );
      if (response) await GetCollectionByID(collection_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCollectionByIDApi = createAsyncThunk(
  "collectionDetails/getCollectionByIDApi",
  async ({ collection_id }, { rejectWithValue }) => {
    try {
      let response = await GetCollectionByID(collection_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const collectionDetailsSlice = createSlice({
  name: "collectionDetails",
  initialState,
  reducers: {
    handleAddPortFolioLocal: (state, action) => {
      state.localCollectionItemList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCollectionApi.pending, (state) => {
        state.collection.loading = true;
        state.collection.error = null;
      })
      .addCase(createCollectionApi.fulfilled, (state, action) => {
        state.collection.loading = false;
        state.new_created_collections_id = action.payload;
      })
      .addCase(createCollectionApi.rejected, (state, action) => {
        state.collection.loading = false;
        state.collection.error = action.payload;
      });
    builder
      .addCase(getCollectionByIDApi.pending, (state) => {
        state.collection.loading = true;
        state.collection.error = null;
      })
      .addCase(getCollectionByIDApi.fulfilled, (state, action) => {
        state.collection.loading = false;
        state.collection.data = action.payload;
      })
      .addCase(getCollectionByIDApi.rejected, (state, action) => {
        state.collection.loading = false;
        state.collection.error = action.payload;
      });
  },
});

export const { handleAddPortFolioLocal } = collectionDetailsSlice.actions;
export default collectionDetailsSlice.reducer;
