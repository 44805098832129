import Datafetch from "../../utils/Datafetch";

const BearerToken = localStorage.getItem("token");
export default async function GetCollectionByID(
  collectionID,
  AccessToken = BearerToken
) {
  const query = `query PortfolioCollection {
    portfolioCollection(id: ${collectionID}) {
        data {
            id
            attributes {
                createdAt
                updatedAt
                publishedAt
                portfolios {
                    data {
                        id
                        attributes {
                            Title
                            Description
                            createdAt
                            updatedAt
                            publishedAt
                            Images {
                              data {
                                  id
                                  attributes {
                                      name
                                      alternativeText
                                      caption
                                      width
                                      height
                                      formats
                                      hash
                                      ext
                                      mime
                                      size
                                      url
                                      previewUrl
                                      provider
                                      provider_metadata
                                      createdAt
                                      updatedAt
                                  }
                              }
                          }
                          PDF {
                              data {
                                  id
                                  attributes {
                                      name
                                      alternativeText
                                      caption
                                      width
                                      height
                                      formats
                                      hash
                                      ext
                                      mime
                                      size
                                      url
                                      previewUrl
                                      provider
                                      provider_metadata
                                      createdAt
                                      updatedAt
                                  }
                              }
                          }
                          portfolio_types {
                              data {
                                  id
                                  attributes {
                                      Name
                                      createdAt
                                      updatedAt
                                      publishedAt
                                      portfolios {
                                          data {
                                              id
                                              attributes {
                                                  Title
                                                  createdAt
                                                  updatedAt
                                                  publishedAt
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          industries {
                              data {
                                  id
                                  attributes {
                                      Name
                                      createdAt
                                      updatedAt
                                      publishedAt
                                      portfolios {
                                          data {
                                              id
                                              attributes {
                                                  Title
                                                  createdAt
                                                  updatedAt
                                                  publishedAt
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          technologies {
                              data {
                                  id
                                  attributes {
                                      Name
                                      createdAt
                                      updatedAt
                                      publishedAt
                                      portfolios {
                                          data {
                                              id
                                              attributes {
                                                  Title
                                                  createdAt
                                                  updatedAt
                                                  publishedAt
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          concepts {
                              data {
                                  id
                                  attributes {
                                      Name
                                      createdAt
                                      updatedAt
                                      publishedAt
                                      portfolios {
                                          data {
                                              id
                                              attributes {
                                                  Title
                                                  createdAt
                                                  updatedAt
                                                  publishedAt
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          project_types {
                              data {
                                  id
                                  attributes {
                                      Name
                                      createdAt
                                      updatedAt
                                      publishedAt
                                      portfolios {
                                          data {
                                              id
                                              attributes {
                                                  Title
                                                  createdAt
                                                  updatedAt
                                                  publishedAt
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          portfolio_collection {
                              data {
                                  id
                                  attributes {
                                      createdAt
                                      updatedAt
                                      publishedAt
                                  }
                              }
                          }
                        }
                    }
                }
            }
        }
    }
}`;

  try {
    let storeFetchPara = {
      method: "POST",
      query,
      //   variables: { id },
    };

    if (AccessToken !== undefined) {
      let BearerToken = AccessToken;
      storeFetchPara = {
        method: "POST",
        query,
        // variables: { id },
        BearerToken,
      };
    }

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
    }

    if (body?.data !== null) {
      return body?.data?.portfolioCollection?.data;
    }
  } catch (error) {
    console.log(`Error in testDataFetch ${error.message}`);
  }
}
