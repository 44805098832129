/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import HamburgarMenu from "../assets/image/hamburgar-menu.svg";
import Filter from "../assets/image/filter-icon.svg";
import Search from "../assets/image/search.svg";
import CloseIcon from "../assets/image/close-icon.svg";
import UpArrowIcon from "../assets/image/up-arrow.svg";
import { useNavigate } from "react-router-dom";
import projectTypeFetch from "../services/FilterData/projectTypeFilter";
import technologiesFetch from "../services/FilterData/TechnologiesFilter";
import industriesFetch from "../services/FilterData/industriseFilter";
import skillsConceptFetch from "../services/FilterData/skilsConceptFilter";
import MainImage from '../assets/image/logo.webp';
import Loginlogo from "../assets/image/login-icon.svg";
import logo from "../assets/image/login-black-icon.svg";
import { useSelector } from "react-redux";
import LogoutPopup from "./logoutPopup";

const Sidebar = ({ activeTab, handleFilterChange }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [accordionData, setAccordionData] = useState([]);
    const [visibleItemCount, setVisibleItemCount] = useState({});
    const [selectedItems, setSelectedItems] = useState({});
    const [filterState, setFilterState] = useState({});
    const Navigate = useNavigate()
    const [newopenPopup, setNewOpenPopup] = useState(false);

    const auth = useSelector((state) => state.loginSlice);

    const handleOpenLogoutPopup = () => {
        setNewOpenPopup(true);
    };

    const handleCloseLogoutPopup = () => {
        setNewOpenPopup(false);
    };

    useEffect(() => {
        fetchInitialData();
        // loadFromLocalStorage();
    }, []);

    useEffect(() => {
        handleFilterChange(filterState);
    }, [filterState, handleFilterChange]);

    const fetchInitialData = async () => {
        try {
            const [
                projectTypeData,
                technologiesData,
                industriesData,
                skillsConceptData,
            ] = await Promise.all([
                projectTypeFetch(),
                technologiesFetch(),
                industriesFetch(),
                skillsConceptFetch(),
            ]);
            const updatedAccordionData = [
                {
                    title: "Project Type",
                    items: projectTypeData?.data?.map((item) => ({
                        label: item.attributes.Name,
                        id: item?.id,
                        count: item.attributes.portfolios.data.length > 0 ? item.attributes.portfolios.data.length : null,
                    })),
                    itemCount: projectTypeData?.data?.length,
                    isOpen: false,
                },
                {
                    title: "Industries",
                    items: industriesData?.data?.map((item) => ({
                        id: item?.id,
                        label: item.attributes.Name,
                        count: item.attributes.portfolios.data.length > 0 ? item.attributes.portfolios.data.length : null,
                    })),
                    itemCount: industriesData?.data?.length,
                    isOpen: false,
                },
                {
                    title: "Technologies",
                    items: technologiesData?.data?.map((item) => ({
                        id: item?.id,
                        label: item.attributes.Name,
                        count: item.attributes.portfolios.data.length > 0 ? item.attributes.portfolios.data.length : null,
                    })),
                    itemCount: technologiesData?.data?.length,
                    isOpen: false,
                },
                {
                    title: "Skills/Concepts",
                    items: skillsConceptData?.data?.map((item) => ({
                        id: item?.id,
                        label: item.attributes.Name,
                        count: item.attributes.portfolios.data.length > 0 ? item.attributes.portfolios.data.length : null,
                    })),
                    itemCount: skillsConceptData?.data?.length,
                    isOpen: false,
                },
            ];

            setAccordionData(updatedAccordionData);

            const initialVisibleCount = updatedAccordionData.reduce(
                (acc, section) => {
                    acc[section.title] = 5;
                    return acc;
                },
                {}
            );
            setVisibleItemCount(initialVisibleCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // const loadFromLocalStorage = () => {
    //     const savedState = localStorage.getItem("Industries");
    //     if (savedState) {
    //       const parsedState = JSON.parse(savedState);
    //       if (parsedState && parsedState.id && parsedState.label) {
    //         setSelectedItems({
    //           industries: [parsedState],
    //         });

    //         setFilterState((prevFilterState) => {
    //             const updatedFilterState = { ...prevFilterState };
    //             updatedFilterState.industries = {
    //                 id: { in: [parsedState.id] },
    //                 labels: [parsedState.label],
    //             };
    //             return updatedFilterState;
    //         });
    //       }
    //     }
    //   };
      
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // if (query.length >= 3) {
    
        setFilterState((prevFilterState) => {
            const updatedFilterState = { ...prevFilterState };
    
            if (query.length >= 3) {
                updatedFilterState.Title = { contains: query };
                // updatedFilterState.Description = { contains: query };
            } else {
                delete updatedFilterState.Title;
                // delete updatedFilterState.Description;
            }
    
            return updatedFilterState;
        });
    // }
    };
    
    const toggleAccordion = (index) => {
        setAccordionData((currentData) =>
            currentData.map((item, idx) => ({
                ...item,
                isOpen: idx === index ? !item.isOpen : item.isOpen,
            }))
        );
    };

    const toggleShowMore = (sectionTitle, section) => {
        setVisibleItemCount((prevCounts) => ({
            ...prevCounts,
            [sectionTitle]:
                prevCounts[sectionTitle] < section?.itemCount
                    ? prevCounts[sectionTitle] + 20
                    : 5,
        }));
    };
    const handleCheckboxChange = (sectionTitle, item, isChecked) => {
        setSelectedItems((prevSelectedItems) => {
            const updatedItems = { ...prevSelectedItems };

            if (isChecked) {
                if (!updatedItems[sectionTitle]) {
                    updatedItems[sectionTitle] = [];
                }
                if (!updatedItems[sectionTitle].some(selectedItem => selectedItem.id === item.id)) {
                    updatedItems[sectionTitle].push(item);
                }
            } else {
                updatedItems[sectionTitle] = updatedItems[sectionTitle].filter(
                    (selectedItem) => selectedItem.id !== item.id
                );
                if (updatedItems[sectionTitle].length === 0) {
                    delete updatedItems[sectionTitle];
                }
            }
            return updatedItems;
        });

        const keyMap = {
            "Project Type": "project_types",
            "Industries": "industries",
            "Technologies": "technologies",
            "Skills/Concepts": "concepts",
        };

        setFilterState((prevFilterState) => {
            const updatedFilterState = { ...prevFilterState };

            if (isChecked) {
                if (!updatedFilterState[keyMap[sectionTitle]]) {
                    updatedFilterState[keyMap[sectionTitle]] = {
                        id: { in: [] },
                        labels: []
                    };
                }
                if (!updatedFilterState[keyMap[sectionTitle]].id.in.includes(item.id)) {
                    updatedFilterState[keyMap[sectionTitle]].id.in.push(item.id);
                    updatedFilterState[keyMap[sectionTitle]].labels.push(item.label);
                }
            } else {
                updatedFilterState[keyMap[sectionTitle]].id.in = updatedFilterState[keyMap[sectionTitle]].id.in.filter(
                    (id) => id !== item.id
                );
                updatedFilterState[keyMap[sectionTitle]].labels = updatedFilterState[keyMap[sectionTitle]].labels.filter(
                    (label) => label !== item.label
                );
                if (updatedFilterState[keyMap[sectionTitle]].id.in.length === 0) {
                    delete updatedFilterState[keyMap[sectionTitle]];
                }
            }

            return updatedFilterState;
        });
    };

    const clearAllSelections = () => {
        setSelectedItems({});
        setFilterState({});
    };

    const handleClearMenu = (sectionTitle, item) => {
        setSelectedItems((prevSelectedItems) => {
            const updatedItems = { ...prevSelectedItems };

            if (updatedItems[sectionTitle]) {
                updatedItems[sectionTitle] = updatedItems[sectionTitle].filter(
                    (selectedItem) => selectedItem.id !== item.id
                );

                if (updatedItems[sectionTitle].length === 0) {
                    delete updatedItems[sectionTitle];
                }
            }
            return updatedItems;
        });

        const keyMap = {
            "Project Type": "project_types",
            "Industries": "industries",
            "Technologies": "technologies",
            "Skills/Concepts": "concepts",
        };

        setFilterState((prevFilterState) => {
            const updatedFilterState = { ...prevFilterState };
            updatedFilterState[keyMap[sectionTitle]].id.in = updatedFilterState[keyMap[sectionTitle]].id.in.filter(
                (id) => id !== item.id
            );
            if (updatedFilterState[keyMap[sectionTitle]].id.in.length === 0) {
                delete updatedFilterState[keyMap[sectionTitle]];
            }
            return updatedFilterState;
        });
    };

    return (
        <>
            <div className="w-full md:max-w-[282px] p-4 md:p-5 border-r-0 md:border-r border-litegray border-b md:border-b-0 static md:sticky top-0">
                {/* Header Section */}
                <div class="flex items-center justify-between">
                    <div class="block md:hidden p-2.5 border border-litegray cursor-pointer w-10 h-10" onClick={toggleMobileMenu}><img
                        src={HamburgarMenu} width="20" height="20" alt="Close Icon" /></div>
                    <a href="#"><img src={MainImage} class="w-[130px] md:w-[200px]" alt="Logo" /></a>
                    <div class="block md:hidden p-2.5 border border-litegray cursor-pointer w-10 h-10"><img
                        src={Filter} width="20" height="20" alt="Close Icon" /></div>
                </div>

                {/* Mobile Menu Content */}
                <div
                    className={`md:hidden ${isMobileMenuOpen ? "" : "hidden"
                        } text-black bg-grybg mt-[30px]`}
                ></div>

                {/* Search Section */}
                <div class="hidden md:block text-black bg-grybg mt-[30px]">
                    <div className="overflow-hidden flex justify-between text-primary relative">
                        <input
                            type="text"
                            className="w-full bg-grybg p-3 pl-4 pr-10 text-sm leading-7 text-primary placeholder:text-gray focus:outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <button
                            className="absolute right-4 top-1/2 transform -translate-y-1/2"
                            style={{ zIndex: 10 }}
                        >
                            <img src={Search} width="20" alt="Search Icon" />
                        </button>
                    </div>
                </div>

                {/* Accordion Sections */}
                <div className="flex items-start gap-2.5 justify-between">
                    <div className="mt-[30px] flex flex-wrap gap-2">
                        {Object.keys(selectedItems)?.map((section, index) =>
                            selectedItems[section].map((selectedItem) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 bg-liteblue px-3"
                                >
                                    <h6 className="text-sm text-primary leading-7">{selectedItem.label}</h6>
                                    <img
                                        src={CloseIcon}
                                        onClick={() => handleClearMenu(section, selectedItem)}
                                        className="cursor-pointer"
                                        width="12"
                                        height="12"
                                        alt="Close Icon"
                                    />
                                </div>
                            ))
                        )}
                    </div>
                    <div className="block md:hidden mt-[30px] px-2.5 border-l border-litegray">
                        <a
                            href="#"
                            className="text-sm text-secondary font-medium text-nowrap"
                            onClick={clearAllSelections}
                        >
                            Clear all
                        </a>
                    </div>
                </div>

                {/* Desktop Accordion */}
                <div className="hidden md:block overflow-y-scroll h-[calc(100vh-277px)]">
                    {accordionData.map((section, index) => {
                        console.log(section,index, "ar")
                        return  <div key={index}  className={`pb-4 mt-[20px] border-litegray ${
                            section.title !== "Skills/Concepts" ? "border-b" : ""
                          }`}>
                        <h2 className="">
                            <button
                                type="button"
                                className="flex items-center justify-between w-full gap-3"
                                aria-expanded={section.isOpen}
                                onClick={() => toggleAccordion(index)}
                            >
                                <span className="text-lg text-primary font-semibold">
                                    {section.title}
                                </span>
                                <img
                                    src={UpArrowIcon}
                                    alt="Expand Icon"
                                    style={{
                                        transform: section.isOpen
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                    }}
                                />
                            </button>
                        </h2>
                        {section.isOpen && (
                            <ul>
                                {section.items
                                    .slice(0, visibleItemCount[section.title])
                                    .map((item, idx) => (
                                        <li key={idx}>
                                            <div className="flex items-center justify-between mt-3">
                                                <form>
                                                    <div className="form-group block">
                                                        <input
                                                            type="checkbox"
                                                            id={`${item.label}-${index}-${idx}`}
                                                            className="hidden cursor-pointer"
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    section.title,
                                                                    item,
                                                                    e.target.checked
                                                                )
                                                            }
                                                            checked={
                                                                selectedItems[section.title] &&
                                                                selectedItems[section.title].some(
                                                                    (selectedItem) => selectedItem.id === item.id
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`${item.label}-${index}-${idx}`}
                                                            className="relative cursor-pointer text-base text-primary"
                                                        >
                                                            {item.label}
                                                        </label>
                                                    </div>
                                                </form>
                                                {item.count && (
                                                    <span className="text-base text-gray font-source-code-pro">
                                                        ({item.count})
                                                    </span>
                                                )}
                                            </div>
                                        </li>
                                    ))}

                                {section.itemCount > 5 && (
                                    <button
                                        className="mt-4 text-base text-secondary leading-7 font-source-code-pro"
                                        onClick={() => toggleShowMore(section.title, section)}
                                    >
                                        {visibleItemCount[section.title] < section?.itemCount
                                            ? "+20 more"
                                            : "Show less"}
                                    </button>
                                )}
                            </ul>
                        )}
                    </div>
                       
})}
                </div>

                {/* Login Button */}
                {
                    auth.jwt ? <div class="flex items-start gap-2 p-3 justify-between sticky left-0 bottom-[30px]">
                        <div class="">
                            <h6 class="text-lg text-black">{auth?.user?.username}</h6>
                            <p class="text-sm text-gray">{auth?.user?.email}</p>
                        </div>
                        <img src={logo} alt="fwf" className='cursor-pointer' onClick={handleOpenLogoutPopup} />
                    </div> :
                        <div className='sticky bottom-0 left-0 bg-white'>
                            <button type="button" onClick={() => Navigate("/login")}
                                class="inline-flex justify-between p-4 text-base font-semibold text-white text-center bg-secondary w-full mt-[30px]">Login
                                <img src={Loginlogo} alt="Login Icon" />
                            </button>
                        </div>
                }
            </div >
            {newopenPopup && <LogoutPopup handleClose={handleCloseLogoutPopup} />}
        </>
    );
};

export default Sidebar;
