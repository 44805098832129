// services/portfolio/createShare.js
const createShare = async ({ Name, Email, portfolio }) => {
  const response = await fetch('https://aioapi.tridhyatech.com/api/contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        Name,
        Email,
        portfolio: {
          id: portfolio
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error('Failed to create share');
  }

  return response.json();
};

export default createShare;
