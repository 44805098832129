import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../assets/image/close-icon.svg";
import DeleteIcon from "../../assets/image/delate-icon.svg";
import {
  getCollectionByIDApi,
  handleAddPortFolioLocal,
  updateCollectionApi,
} from "../../redux/Slices/collectionDetailsSlice";
import SharePopupSendMail from "./SendMail";

const CollectionSidebar = ({ onClose }) => {
  const dispatch = useDispatch();
  const collectionData = useSelector((state) => state.collectionDetail);
  const { localCollectionItemList } = useSelector(
    (state) => state.collectionDetail
  );
  const [openPopup, setOpenPopup] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Base URL of your API

  const fetchData = async () => {
    if (collectionData?.new_created_collections_id) {
      await dispatch(
        getCollectionByIDApi({
          collection_id: collectionData?.new_created_collections_id,
        })
      );
    }
  };

  const removeFromCollection = async (id) => {
    await dispatch(
      updateCollectionApi({
        collection_id: collectionData?.new_created_collections_id,
        portfolio_ids: collectionData?.localCollectionItemList?.filter(
          (i) => i !== id
        ),
      })
    );
    dispatch(
      handleAddPortFolioLocal(
        collectionData?.localCollectionItemList?.filter((i) => i !== id)
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, [localCollectionItemList]);

  const downloadFile = async (url, fileName) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleDownloadAll = async (event) => {
    event.preventDefault();
    const portfolios =
      collectionData?.collection?.data?.attributes?.portfolios?.data || [];

    let anyFilesToDownload = false;

    for (const item of portfolios) {
      const url = item?.attributes?.PDF?.data?.[0]?.attributes?.url;
      if (url) {
        anyFilesToDownload = true;
        const fullUrl = `${API_BASE_URL}${url}`;
        const fileName = fullUrl.split("/").pop();
        await downloadFile(fullUrl, fileName);
      }
    }

    if (!anyFilesToDownload) {
      alert("No files available for download.");
    }
  };

  return (
    <>
      <div className="fixed top-0 right-0 w-[432px] h-screen bg-white shadow-lg z-50 overflow-y-scroll pb-3">
        <div className="px-[30px] py-5 flex items-center justify-between border-b border-[#EBEBEB]">
          <h6 className="text-2xl font-semibold text-primary">Collection</h6>
          <div
            className="p-2.5 border border-litegray cursor-pointer w-10 h-10"
            onClick={onClose}
          >
            <img
              src={CloseIcon}
              width="20"
              height="20"
              className="cursor-pointer"
              alt="Close Icon"
            />
          </div>
        </div>
        <div className="px-[30px] py-5">
          {collectionData?.collection?.data?.attributes?.portfolios?.data?.map(
            (item, index) => (
              <div
                key={index}
                className="flex items-start justify-between mb-[30px]"
              >
                <div className="flex items-start gap-2">
                  <a href="#">
                    <img
                      src={`${API_BASE_URL}/${item?.attributes?.Images?.data[0]?.attributes?.url}`}
                      className="max-w-[120px]"
                      alt="Furniture App"
                    />
                  </a>
                  <div className="px-1 pt-1 pb-6 max-w-[200px] w-full">
                    <span className="text-base text-primary font-semibold collection-text">
                      {item?.attributes?.Title}
                    </span>
                    <h6 className="text-xs text-gray font-semibold">
                      Portfolio
                    </h6>
                  </div>
                </div>
                <button>
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                    onClick={() => removeFromCollection(item?.id)}
                  />
                </button>
              </div>
            )
          )}
        </div>

        {collectionData?.collection?.data?.attributes?.portfolios?.data
          ?.length > 0 && (
          <div className="px-[30px]">
            <span
              className="px-9 py-4 block text-center bg-secondary text-lg font-semibold text-white shadow-0.15 mb-1.5 cursor-pointer"
              onClick={handleDownloadAll}
            >
              Download All
            </span>
            <a
              href="#"
              className="px-9 py-4 block text-center bg-white text-lg font-semibold text-primary shadow-0.15"
              onClick={() => setOpenPopup(true)}
            >
              Send E-mail
            </a>
          </div>
        )}

        {openPopup && (
          <SharePopupSendMail
            id={
              collectionData?.collection?.data?.attributes?.portfolios
                ?.data?.[0]?.id
            }
            handleClose={setOpenPopup}
          />
        )}
      </div>
    </>
  );
};

export default CollectionSidebar;
