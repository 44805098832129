/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  handleAddPortFolioLocal,
} from "../../redux/Slices/collectionDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import MailIcon from "../../assets/image/mail.svg";
import SharePopup from "../../Pages/sharePopup";
import Spinner from "../../shared/Spinner";
import HeartIcon from "../../assets/image/heart.svg";
import { fetchPortfolios } from "../../redux/Slices/portfoliosSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

const Home = ({ activeTab, filterState }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [portFolios, setPortfolios] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  // const [protFolios, setProtfolios] = useState([]);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState({});
  const auth = useSelector((state) => state.loginSlice);
  const collectionData = useSelector((state) => state.collectionDetail);
  const observer = useRef();

  const fetchData = useCallback(
    async (page, activeTab, filterState) => {
      setLoading(true);
      const queryFilters = {};
      // for (const key in filterState) {
      //   if (filterState[key]?.id?.in) {
      //     queryFilters[key] = { id: filterState[key].id };
      //   }
      // }

      for (const key in filterState) {
        if (filterState[key]?.id?.in) {
          queryFilters[key] = { id: filterState[key].id };
        } else if (filterState[key]?.contains) {
          queryFilters[key] = { contains: filterState[key].contains };
        }
      }

      const dataRes = await dispatch(
        fetchPortfolios({ page, activeTab, filters: queryFilters })
      );

      if (dataRes && dataRes.payload) {
        const portfolios =
          activeTab === "0"
            ? dataRes.payload.portfolios
            : dataRes.payload.portfolioType?.data.attributes.portfolios;

        if (portfolios?.data) {
          setPortfolios((prevPortfolios) =>
            page === 1
              ? portfolios.data
              : [...prevPortfolios, ...portfolios.data]
          );

          const currentPage = portfolios.meta?.pagination.page || 1;
          const pageCount = portfolios.meta?.pagination.pageCount || 1;
          setHasMore(currentPage < pageCount);
        } else {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }

      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    setPortfolios([]);
    setPage(1);
    setHasMore(true);
    // const localStorageFilter = JSON.parse(localStorage.getItem('Industries'));
    // const mergedFilterState = localStorageFilter
    //   ? { ...filterState, industries: { id: { in: [localStorageFilter?.id] } } }
    //   : filterState;
    // fetchData(1, activeTab, mergedFilterState);
    fetchData(1, activeTab, filterState);
  }, [activeTab, filterState, fetchData]);

  useEffect(() => {
    if (page > 1) {
      fetchData(page, activeTab, filterState);
    }
  }, [page, activeTab, filterState, fetchData]);

  // Infinite Scroll Handler
  const lastPortfolioElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  // useEffect(() => {
  //   const throttledHandleScroll = () => handleScroll();
  //   window.addEventListener('scroll', throttledHandleScroll);
  //   return () => window.removeEventListener('scroll', throttledHandleScroll);
  // }, [handleScroll]);
  const handleOpenSharePopup = (e, itemId) => {
    e.preventDefault();
    setSelectedItemId(itemId);
    setOpenPopup(true);
  };

  const handleCloseSharePopup = () => {
    setOpenPopup(false);
    setSelectedItemId(null);
  };

  const keyMap = {
    project_types: "Project Type",
    industries: "Industries",
    technologies: "Technologies",
    concepts: "Skills/Concepts",
  };

  // const getActiveFilterDisplay = (filterState) => {
  //   const activeFilters = [];
  //   for (const key in filterState) {
  //     if (Array.isArray(filterState[key]?.labels)) {
  //       activeFilters.push({
  //         filterName: keyMap[key],
  //         filterValue: filterState[key].labels.join(", "),
  //       });
  //     }
  //   }

  //   return activeFilters.length > 0 ? activeFilters : null;
  // };

  const handleImageLoad = (id) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleImageLoadStart = (id) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  // const activeFilterDisplay = getActiveFilterDisplay(filterState);

  const hasActiveFilters = () => {
    for (const key in filterState) {
      if (filterState[key]?.contains) {
        return true;
      }
    }
    return false;
  };

  const addItemInCollection = async (id) => {
    if (collectionData?.new_created_collections_id) {
      // const dataRes = await dispatch(
      //   updateCollectionApi({
      //     collection_id: collectionData?.new_created_collections_id,
      //     portfolio_ids: [...collectionData?.localCollectionItemList, id],
      //   })
      // );
      dispatch(
        handleAddPortFolioLocal([
          ...collectionData?.localCollectionItemList,
          id,
        ])
      );
    } else {
      // const dataRes = await dispatch(
      //   createCollectionApi({
      //     user_id: auth?.user?.id,
      //     portfolio_ids: [id],
      //   })
      // );
      dispatch(handleAddPortFolioLocal([id]));
    }
  };

  return (
    <>
      {hasActiveFilters() && (
        <p className="pl-[30px] pt-6 text-lg text-primary">
          Showing results for{" "}
          {/* {activeFilterDisplay ? (
            activeFilterDisplay.map((filter, index) => (
              <span key={index} className="text-secondary">
                {index > 0 && ", "}
                {filter.filterName}: {filter.filterValue}
              </span>
            ))
          ) : ( */}
          <span className="text-secondary">{filterState?.Title?.contains}</span>
          {/* )} */}
        </p>
      )}

      <div id="tab-contents">
        {portFolios?.length > 0 ? (
          <div className="p-[30px] active" tab-id="first">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-[30px]">
              {portFolios?.map((item, index) => (
                <div key={item.id}>
                  <div
                    className="col-12 col-sm-6 col-lg-3"
                    ref={
                      index === portFolios.length - 1
                        ? lastPortfolioElementRef
                        : null
                    }
                  >
                    {imageLoading[item.id] ||
                      !item.attributes.Images.data.length ? (
                      <Skeleton height={286} />
                    ) : (
                      <Link to={`/details/${item?.id}`}>
                        <img
                          src={
                            item?.attributes?.Images?.data?.length > 0
                              ? `${process.env.REACT_APP_API_BASE_URL}${item.attributes.Images.data[0].attributes.formats.small.url}`
                              : null
                          }
                          className="w-full"
                          alt={item.attributes.Title}
                          onLoad={() => handleImageLoad(item.id)}
                          onLoadStart={() => handleImageLoadStart(item.id)}
                        />
                      </Link>
                    )}
                    <div className="mx-1 my-2">
                      <div className="flex items-center justify-between mb-1.5">
                        <Link
                          to={`/details/${item?.id}`}
                          className="text-base text-primary font-semibold w-[246px] truncate"
                        >
                          {item.attributes.Title}
                        </Link>
                        <div className="flex items-center gap-2">
                          {auth.jwt && (
                            <a
                              href="#"
                              onClick={() => addItemInCollection(item?.id)}
                            >
                              <img src={HeartIcon} alt="Heart Icon" />
                            </a>
                          )}
                          <a
                            href="#"
                            onClick={(e) => handleOpenSharePopup(e, item?.id)}
                          >
                            <img src={MailIcon} alt="Mail Icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <h6 className="text-xs text-gray font-semibold pl-1">
                      {
                        item.attributes?.portfolio_types?.data.length > 0
                          ? item.attributes.portfolio_types.data
                            .map(portfolioType => portfolioType.attributes.Name)
                            .join(' / ')
                          : null
                      }
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : !loading ? (
          <div className="text-center p-6">
            <p className="text-2xl font-medium text-primary mb-5">
              No items found
            </p>
            <p className="text-base text-gray">The list is currently empty.</p>
          </div>
        ) : null}
      </div>

      {loading && <Spinner />}
      {openPopup && (
        <SharePopup
          handleClose={handleCloseSharePopup}
          itemId={selectedItemId}
        />
      )}
    </>
  );
};

export default Home;
