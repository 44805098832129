import React, { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import WebLayout from './layout/WebLayout';
import SignIn from './Pages/SignIn';
import Spinner from './shared/Spinner';
import Home from './Components/Home';
import DetailsPage from './Components/Details';
// import { useSelector } from 'react-redux';

const AppRoutes = () => {
  // const navigate = useNavigate()
  // const auth = useSelector((state) => state.loginSlice);
  //   useEffect(()=>{
  //   if (auth.jwt) {
  //     navigate("/")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[auth.jwt])

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<SignIn />}/>
        <Route exact path="/" element={<WebRoute component={Home} />} />
        <Route exact path="/details/:id" element={<DetailsPage />} />
      </Routes>
    </Suspense>
  );
}

const WebRoute = ({ component: Component }) => {
  return (
    <WebLayout>
      <Component />
    </WebLayout>
  );
};

export default AppRoutes;