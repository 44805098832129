import Datafetch from '../../utils/Datafetch';

export default async function ProtfolioList(page = 1,filters) {
    const query = `
      query PortfolioType($page: Int, $filters: PortfolioFiltersInput) {
    portfolioType(id: "1") {
        data {
            id
            attributes {
                Name
                createdAt
                updatedAt
                publishedAt
                portfolios(filters: $filters, pagination: { page: $page, pageSize: 12 }) {
                    data {
                        id
                        attributes {
                            Title
                            Description
                            createdAt
                            updatedAt
                            publishedAt
                            Images {
                                data {
                                    id
                                    attributes {
                                        name
                                        alternativeText
                                        caption
                                        width
                                        height
                                        formats
                                        hash
                                        ext
                                        mime
                                        size
                                        url
                                        previewUrl
                                        provider
                                        provider_metadata
                                        createdAt
                                        updatedAt
                                    }
                                }
                            }
                            portfolio_types {
                                data {
                                    id
                                    attributes {
                                        Name
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
  `;

    try {
        let storeFetchPara = {
            method: 'POST',
            query,
            variables: { page, filters },
        };

        // if (AccessToken !== undefined) {
        //     let BearerToken = AccessToken;
        //     storeFetchPara = {
        //         method: 'POST',
        //         query,
        //         variables: { page },
        //         BearerToken,
        //     };
        // }

        const { status, body } = await Datafetch(storeFetchPara);

        // Check for HTTP status and response body
        if (status !== 200 || !body || body.errors) {
            if (body?.errors && body?.errors.length > 0) {
                throw new Error(body?.errors[0]?.message);
            }
        }

        if (body?.data !== null) {
            return body?.data;
        }
    } catch (error) {
        console.log(`Error in testDataFetch ${error.message}`);
    }
}
