import Datafetch from '../../utils/Datafetch';

export default async function loginService(identifier, password) {
  const query = `
    mutation Login($identifier: String!, $password: String!) {
      login(input: { identifier: $identifier, password: $password }) {
        jwt
        user {
          id
          username
          email
          confirmed
          blocked
          role {
            id
            name
            description
            type
          }
        }
      }
    }
  `;

  const variables = {
    identifier,
    password
  };

  try {
    const storeFetchPara = {
      method: 'POST',
      query,
      variables
    };

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
    }

    if (body?.data !== null) {
      return body?.data;
    }
  } catch (error) {
    console.log(`Error in loginService: ${error.message}`);
  }
}
