/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '../assets/image/close-icon.svg';
// import Twitter from '../assets/image/twitter.svg';
// import Instagram from '../assets/image/instagram.svg';
// import Facebook from '../assets/image/facebook.svg';
// import Linkedin from '../assets/image/linkedin.svg';
import { submitShare } from '../redux/Slices/shareSlice';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import WhiteSpinner from '../shared/WhiteSpinner';

// Define Yup schema for validation
const validationSchema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  Email: Yup.string().email('Invalid email format').required('Email is required'),
});

const SharePopup = ({ handleClose, itemId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const dispatch = useDispatch();
  const shareData = useSelector((state) => state.share.share);
  console.log('shareData', shareData);

  const onSubmit = async (data) => {
    try {
      await dispatch(submitShare({ ...data, portfolio: itemId }));
      handleClose();
      toast.success('Email sent successfully');

    } catch (error) {
      toast.error('Something went wrong');
      console.error('Failed to create share:', error.message);
    }
  };

  // const shareUrl = `https://aio.tridhyatech.com/#/project-detail/${itemId}`;


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[rgba(34,34,34,0.05)] z-50">
      <div className="max-w-[458px] w-full p-[30px] bg-white relative z-10">
        <h6 className="text-lg md:text-2xl text-primary font-semibold text-center py-1">Share</h6>
        <img src={CloseIcon} width="20" className="absolute top-4 right-4 cursor-pointer" alt="Close Icon" onClick={handleClose} />
        <form 
        onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mt-8">
            <div className="mb-[18px]">
              <label htmlFor="name" className="block mb-2 text-base font-semibold text-primary">Enter Name</label>
              <input
                type="text"
                id="name"
                {...register('Name')}
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter name"
              />
              {errors.Name && <p className="text-red-500 text-sm">{errors.Name.message}</p>}
            </div>
            <div className="">
              <label htmlFor="email" className="block text-base font-semibold text-primary">Enter Email</label>
              <input
                type="email"
                id="email"
                {...register('Email')}
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter email"
              />
              {errors.Email && <p className="text-red-500 text-sm">{errors.Email.message}</p>}
            </div>
            {shareData && shareData.loading ? (
              <button
                className="mt-6 inline-flex justify-center items-center gap-2.5 px-9 py-1 text-lg font-semibold text-white text-center bg-secondary w-full"
              >
                <WhiteSpinner />
              </button>
            ): (
              <button
                type="submit"
                className="mt-6 inline-flex justify-center items-center gap-2.5 px-9 py-4 text-lg font-semibold text-white text-center bg-secondary w-full"
              >
                Submit
              </button>
            )}
          </div>
          {/* <span className="my-6 block text-lg text-gray text-center">OR</span>
          <div className="flex gap-5 items-center justify-center">
            <a href={`https://twitter.com/intent/tweet?text=https%3A//aio.tridhyatech.com/%23/project-detail/${itemId}`} target="_blank"><img src={Twitter} alt="Twitter" /></a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//aio.tridhyatech.com/%23/project-detail/${itemId}`} target="_blank"><img src={Facebook} alt="Facebook" /></a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A//aio.tridhyatech.com/%23/project-detail/${itemId}`} target="_blank"><img src={Linkedin} alt="Linkedin" /></a>
          </div> */}
        </form>
      </div>    
    </div>
  );
};

export default SharePopup;
