// src/features/auth/loginSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loginService from "../../services/authentication/login";

export const login = createAsyncThunk(
  "auth/login",
  async ({ identifier, password }, thunkAPI) => {
    try {
      const response = await loginService(identifier, password);
      return response.login;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const loginSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    jwt: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        state.jwt = action.payload.jwt;
        localStorage.setItem("token", action.payload.jwt);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default loginSlice.reducer;
