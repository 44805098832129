/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HeartIcon from "../assets/image/heart.svg";
import CollectionSidebar from "../Components/CollectionSidebar";

const Header = ({ activeTab, handleTabClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useSelector((state) => state.loginSlice);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSidebarClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="sticky top-0">
      <ul
        id="tabs"
        className="sticky top-0 bg-white inline-flex w-full border-b [&>*]:border-secondary border-litegray pl-[30px]"
      >
        <div className="flex items-center justify-between w-full">
          <div className="sticky top-0 bg-white inline-flex w-full">
            <li
              className={`px-[14px] py-[26px] cursor-pointer text-lg ${
                activeTab === "0"
                  ? "text-[#06B6E8] border-b border-[#06B6E8]"
                  : "text-gray"
              }`}
              tab-to="0"
              onClick={() => handleTabClick("0")}
            >
              All
            </li>
            <li
              className={`px-[14px] py-[26px] cursor-pointer text-lg ${
                activeTab === "1"
                  ? "text-[#06B6E8] border-b border-[#06B6E8]"
                  : "text-gray"
              }`}
              tab-to="1"
              onClick={() => handleTabClick("1")}
            >
              Portfolios
            </li>
            <li
              className={`px-[14px] py-[26px] cursor-pointer text-lg ${
                activeTab === "2"
                  ? "text-[#06B6E8] border-b border-[#06B6E8]"
                  : "text-gray"
              }`}
              tab-to="2"
              onClick={() => handleTabClick("2")}
            >
              Case Studies
            </li>
          </div>
          {auth.jwt && (
            <div className="mr-[30px]" onClick={toggleSidebar}>
              <div className="py-2 px-4 flex items-center gap-3 border border-litegray ">
                <a href="#" className="block w-6 h-6">
                  <img src={HeartIcon} className="w-6 h-6" alt="Heart Icon" />
                </a>
                <a
                  href="#"
                  className="block text-base font-semibold text-primary"
                >
                  Collections
                </a>
              </div>
            </div>
          )}
        </div>
      </ul>
      {isOpen && <CollectionSidebar onClose={toggleSidebarClose} />}
    </div>
  );
};

export default Header;
