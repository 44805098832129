import Datafetch from '../../utils/Datafetch';

export default async function GetIndustryListById(id, page=1) {
  const query = `
      query Industries($page: Int, $id: ID) {
        industry(id: $id) {
            data {
                id
                attributes {
                    Name
                    createdAt
                    updatedAt
                    publishedAt
                    portfolios(pagination: { page: $page, pageSize: 12 }) {
                        data {
                            id
                            attributes {
                                Title
                                Description
                                Images {
                                    data {
                                        id
                                        attributes {
                                            width
                                            height
                                            url
                                        }
                                    }
                                }
                                portfolio_types {
                                    data {
                                        id
                                        attributes {
                                            Name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  `;

  try {
    let storeFetchPara = {
      method: 'POST',
      query,
      variables: { page, id }
    };

    // if (AccessToken !== undefined) {
    //   let BearerToken = AccessToken;
    //   storeFetchPara = {
    //     method: 'POST',
    //     query,
    //     variables: { page },
    //     BearerToken,
    //   };
    // }

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
    }

    if (body?.data !== null) {
      return body?.data;
    }
  } catch (error) {
    console.log(`Error in testDataFetch ${error.message}`);
  }
}
