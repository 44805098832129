import { combineReducers } from "@reduxjs/toolkit";
import portfoliosReducer from "./Slices/portfoliosSlice";
import loginReducer from "./Slices/loginSlice";
import getPortfolioDetailsSliceReducer from "./Slices/getPortfoliosDetailsSlice";
import colleactionDetailsSliceReducer from "./Slices/collectionDetailsSlice";
import getIndustryDetailsSliceReducer from "./Slices/getIndustryById";
import shareReducer from "./Slices/shareSlice";

const combinedReducer = combineReducers({
  portfolios: portfoliosReducer,
  loginSlice: loginReducer,
  getPortfolioDetail: getPortfolioDetailsSliceReducer,
  collectionDetail: colleactionDetailsSliceReducer,
  industry: getIndustryDetailsSliceReducer,
  share: shareReducer,
});

export const rootReducer = (state, action) => {
  // if (action.type === "auth/Logout") {
  //   state = undefined;
  // }
  return combinedReducer(state, action);
};
