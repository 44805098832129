/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Download from '../../assets/image/download.svg';
import Heart from '../../assets/image/heart.svg';
import MailIcon from '../../assets/image/mail.svg';
import LeftArrow from '../../assets/image/left-arrow.svg';
import MainLogo from '../../assets/image/logo.webp';
import Spinner from '../../shared/Spinner';
import { fetchPortfolioById } from '../../redux/Slices/getPortfoliosDetailsSlice';
import { fetchIndustryById } from '../../redux/Slices/getIndustryById';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SharePopup from '../../Pages/sharePopup';

const Details = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const auth = useSelector((state) => state.loginSlice);
    const getPortfolioById = useSelector((state) => state.getPortfolioDetail.getPortfolioById);
    const getPortfolioByIdLoading = useSelector((state) => state.getPortfolioDetail.getPortfolioById.loading);
    const getIndustryById = useSelector((state) => state.industry.getIndustryById);
    const getIndustryByIdLoading = useSelector((state) => state.industry.getIndustryById.loading)

    useEffect(() => {
        dispatch(fetchPortfolioById(id));
        if (getPortfolioById?.data?.portfolio?.data?.attributes?.industries?.data?.length > 0) {
            dispatch(fetchIndustryById(getPortfolioById?.data?.portfolio?.data?.attributes?.industries?.data[0]?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const handleOpenSharePopup = (e, itemId) => {
        e.preventDefault();
        setSelectedItemId(itemId);
        setOpenPopup(true);
    };

    const handleCloseSharePopup = () => {

        setOpenPopup(false);
        setSelectedItemId(null);
    };

    const handleOpenViewAll = () => {
        localStorage.setItem('Industry', getIndustryById?.data?.industry?.data?.id);
    }

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    console.log(selectedItemId, "openPopup", openPopup)

    return (

        getPortfolioByIdLoading && getPortfolioByIdLoading ? (
            <Spinner />
        ) : (
            <>
                <div className="border-b border-litegray sticky top-0 bg-white z-10">
                    <div className="py-5 min-w-[1202px] lg:min-w-0 max-w-0 lg:max-w-[1202px] mx-auto w-full pl-4 pr-4">
                        <div className="flex items-center gap-6">
                            <a href='/'>
                                <div className="p-2.5 border border-litegray cursor-pointer w-10 h-10">
                                    <img
                                        src={LeftArrow}
                                        width="20"
                                        height="20"
                                        alt="Close Icon"
                                    />
                                </div>
                            </a>
                            <img src={MainLogo} className="w-[200px] h-[40px]" alt="Logo" />
                        </div>
                    </div>
                </div>
                <div className="border-b border-litegray bg-white">
                    <div
                        className="flex items-center justify-between py-[30px] min-w-[1202px] lg:min-w-0 max-w-0 lg:max-w-[1202px] mx-auto w-full pl-4 pr-4">
                        <h4 className="text-2xl font-semibold text-primary">{getPortfolioById && getPortfolioById.data && getPortfolioById.data.portfolio && getPortfolioById.data.portfolio.data && getPortfolioById.data.portfolio.data.attributes && getPortfolioById.data.portfolio.data.attributes.Title}</h4>
                        <div className="flex items-center gap-2.5">
                            {getPortfolioById && getPortfolioById.data && getPortfolioById.data.portfolio && getPortfolioById.data.portfolio.data && getPortfolioById.data.portfolio.data.attributes.PDF?.data?.length > 0 && (
                                <a className="p-2.5 border border-litegray cursor-pointer w-10 h-10"
                                    href={`${process.env.REACT_APP_API_BASE_URL}${getPortfolioById && getPortfolioById.data && getPortfolioById.data.portfolio && getPortfolioById.data.portfolio.data && getPortfolioById.data.portfolio.data.attributes.PDF?.data[0]?.attributes?.url}`} target='_blank' rel="noreferrer">
                                    <img src={Download}

                                        width="20" height="20" alt="Close Icon" /></a>
                            )}
                            {
                                auth.jwt && (
                                    <>
                                        <div className="p-2.5 border border-litegray cursor-pointer w-10 h-10"><img src={Heart}
                                            width="20" height="20" alt="Close Icon" /></div>
                                    </>
                                )}
                            <div className="p-2.5 border border-litegray cursor-pointer w-10 h-10"><img
                                src={MailIcon} width="20" height="20" alt="Close Icon" onClick={(e) => handleOpenSharePopup(e, id)} /></div>
                        </div>
                    </div>
                </div>
                <div className="min-w-[1202px] lg:min-w-0 max-w-0 lg:max-w-[1202px] mx-auto w-full pl-4 pr-4 mt-[30px]">
                    <div className="flex items-start gap-[30px]">
                        <div className="w-[270px] sticky top-[110px] bg-white">
                            <div className="">
                                <div className="mb-[30px]">
                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.project_types?.data?.length > 0 && (
                                        <>
                                            <h2 className="text-lg text-primary font-semibold mb-2">Project Type</h2>
                                            <ul className="flex gap-2 flex-wrap">
                                                {getPortfolioById?.data?.portfolio?.data?.attributes?.project_types?.data?.map((item, index) => (
                                                    <li className="text-base text-primary bg-liteblue inline-block px-4 py-2.5 leading-4" key={index}>
                                                        {item?.attributes?.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>

                                <div className="mb-[30px]">
                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.industries?.data?.length > 0 && (
                                        <>
                                            <h2 className="text-lg text-primary font-semibold mb-2">Industry</h2>
                                            <ul className="flex gap-2 flex-wrap">
                                                {getPortfolioById?.data?.portfolio?.data?.attributes?.industries?.data?.map((item, index) => (
                                                    <li className="text-base text-primary bg-liteblue inline-block px-4 py-2.5 leading-4" key={index}>
                                                        {item?.attributes?.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>

                                <div className="mb-[30px]">
                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.technologies?.data?.length > 0 && (
                                        <>
                                            <h2 className="text-lg text-primary font-semibold mb-2">Technologies</h2>
                                            <ul className="flex gap-2 flex-wrap">
                                                {getPortfolioById?.data?.portfolio?.data?.attributes?.technologies?.data?.map((item, index) => (
                                                    <li className="text-base text-primary bg-liteblue inline-block px-4 py-2.5 leading-4" key={index}>
                                                        {item?.attributes?.Name}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>

                                <div className="mb-[30px]">
                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.concepts?.data?.length > 0 && (
                                        <>
                                            <h2 className="text-lg text-primary font-semibold mb-2">Skills/Concepts</h2>
                                            <ul className="flex gap-2 flex-wrap">
                                                {getPortfolioById?.data?.portfolio?.data?.attributes?.concepts?.data?.map((item, index) => (
                                                    <li className="text-base text-primary bg-liteblue inline-block px-4 py-2.5 leading-4" key={index}>
                                                        {item?.attributes?.Name}</li>
                                                ))}

                                            </ul>
                                        </>
                                    )}
                                </div>

                                <div className="mt-30px">
                                    <ul>
                                        {getPortfolioById?.data?.portfolio?.data?.attributes?.iOS && (
                                            <li className="mb-[30px]">
                                                <p className="text-secondary text-lg font-semibold">
                                                    IOS Application
                                                </p>
                                                <a href={getPortfolioById?.data?.portfolio?.data?.attributes?.iOS} target='_blank' rel="noreferrer" className='break-all anchor-link'>
                                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.iOS}
                                                </a>

                                            </li>
                                        )}
                                        {getPortfolioById?.data?.portfolio?.data?.attributes?.Android && (
                                            <li className="mb-[30px]">
                                                <p className="text-secondary text-lg font-semibold">
                                                    Android Application
                                                </p>
                                                <a href={getPortfolioById?.data?.portfolio?.data?.attributes?.Android} target='_blank' rel="noreferrer" className='break-all anchor-link'>
                                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.Android}
                                                </a>
                                            </li>
                                        )}
                                        {getPortfolioById?.data?.portfolio?.data?.attributes?.Website && (
                                            <li>
                                                <p className="text-secondary text-lg font-semibold">
                                                    Website
                                                </p>
                                                <a href={getPortfolioById?.data?.portfolio?.data?.attributes?.Website} target='_blank' rel="noreferrer" className='break-all anchor-link'>
                                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.Website}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="max-w-[870px] w-full">
                            <div className="gallery-slider">
                                <div className="slider-images mb-[18px]">
                                    {!getPortfolioById?.data?.portfolio?.data?.attributes.Images.data ? (
                                        <Skeleton height={286} />
                                    ) : (
                                        <div className="image-wrapper">
                                            <img src={`${process.env.REACT_APP_API_BASE_URL}${getPortfolioById?.data?.portfolio?.data?.attributes?.Images?.data[currentImageIndex]?.attributes?.formats?.large?.url}`} alt="Portfolio Image" className="w-full" />
                                        </div>
                                    )}
                                </div>
                                <div class="scrollmenu flex gap-[30px] items-center">
                                    {getPortfolioById?.data?.portfolio?.data?.attributes?.Images?.data?.length > 0 ? (
                                        getPortfolioById?.data?.portfolio?.data?.attributes?.Images?.data?.map((item, index) => (
                                            <a key={item.id} onClick={() => handleImageClick(index)}>
                                                <img src={`${process.env.REACT_APP_API_BASE_URL}${item?.attributes?.formats?.small?.url}`} className='h-[90px] max-w-full w-[170px]' alt="Portfolio Image" width="120" />
                                            </a>
                                        ))
                                    ) : (
                                        <div>No images found.</div>
                                    )}
                                </div>
                            </div>
                            <div className="my-[30px]">
                                <p className="text-lg text-primary mb-[30px] text-justify">{getPortfolioById?.data?.portfolio?.data?.attributes?.Description[0]?.children[0]?.text}</p>
                            </div>
                        </div>
                    </div>

                    {getIndustryById?.data?.industry?.data?.attributes?.portfolios?.data?.length > 0 && (
                        <div className="mt-[30px]">
                            <div className="">
                                <div className="flex items-center justify-between py-2 mb-4">
                                    <h6 className="text-2xl font-semibold text-primary">Similar Case Studies</h6>
                                    <a href="/" className="text-base text-secondary" onClick={handleOpenViewAll}>View All</a>
                                </div>
                                {getIndustryByIdLoading && getIndustryByIdLoading.loading ? (
                                    <Spinner />
                                ) : (
                                    <div className="flex gap-[30px]">
                                        {getIndustryById?.data?.industry?.data?.attributes?.portfolios?.data?.length > 0 ? (
                                            getIndustryById?.data?.industry?.data?.attributes?.portfolios?.data?.slice(0, 3).map((item, index) => {
                                                console.log(item?.id, "ieteee")
                                                return <div className="mb-[30px] max-w-[370px] w-full" key={index}>
                                                    <div className="card">
                                                        {!item.attributes.Images.data.length ? (
                                                            <Skeleton height={286} />
                                                        ) : (
                                                            <a href={`/details/${item?.id}`}>
                                                                <img src={item?.attributes?.Images?.data?.length > 0 ? `${process.env.REACT_APP_API_BASE_URL}${item?.attributes?.Images?.data[0]?.attributes?.url}` : null} width="370" className="max-w-[370px] w-full"
                                                                    alt="Furniture App" />
                                                            </a>
                                                        )}
                                                        <div className="px-1 py-2">
                                                            <div className="flex items-start justify-between">
                                                                <a href="#" className="mb-1.5 text-base text-primary font-semibold max-w-[270px]">
                                                                    {item?.attributes?.Title}
                                                                </a>
                                                                <div className="flex items-center gap-2" >
                                                                    {/* <a href="#"><img src={HeartIcon} alt="Heart Icon" /></a> */}
                                                                    <a href="#" onClick={(e) => handleOpenSharePopup(e, item?.id)}><img src={MailIcon} alt="Mail Icon" onClick={(e) => handleOpenSharePopup(e, item?.id)} /></a>
                                                                </div>
                                                            </div>
                                                            <h6 className="text-xs text-gray font-semibold">
                                                                {/* {item.attributes?.portfolio_types?.data.length > 0 ? item.attributes.portfolio_types.data[0].attributes.Name : null} */}
                                                                {item.attributes?.portfolio_types?.data.length > 0
                                                                    ? item.attributes.portfolio_types.data
                                                                        .map(portfolioType => portfolioType.attributes.Name)
                                                                        .join(' / ')
                                                                    : null}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {openPopup && <SharePopup handleClose={handleCloseSharePopup} itemId={selectedItemId} />}

            </>
        )
    )
}

export default Details;