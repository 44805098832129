import Datafetch from "../../utils/Datafetch";

const BearerToken = localStorage.getItem("token");

export default async function sendMail(
  portfolio_id,
  name,
  email,
  AccessToken = BearerToken
) {
  const query = `mutation CreateContact {
    createContact(data: { portfolio: "${portfolio_id}", Name: "${name}", Email: "${email}" }) {
        data {
            id
        }
    }
  }`;

  try {
    let storeFetchPara = {
      method: "POST",
      query,
    };

    if (AccessToken !== undefined) {
      storeFetchPara.headers = {
        Authorization: `Bearer ${AccessToken}`,
      };
    }

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
      throw new Error("Unknown error occurred");
    }

    if (body?.data !== null) {
      return body?.data?.createContact;
    }
  } catch (error) {
    console.error(`Error in sendMail: ${error.message}`);
    throw error;
  }
}
