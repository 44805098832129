import React from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import Spinner from './shared/Spinner';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <React.Suspense fallback={<Spinner />}>
      <AppRoutes />
      <Toaster position="top-center" />
     
    </React.Suspense>
  );
}

export default App;