/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { persistor } from '../redux/store';
import { useNavigate } from 'react-router-dom';

const LogoutPopup = ({ handleClose }) => {
    const navigate = useNavigate()

    const handleLogout =  () => {
         persistor.purge(); // Wait for the persistor to purge
           handleClose();
           navigate("/login")
      };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[rgba(34,34,34,0.05)] z-50">
        <div class="max-w-[458px] w-full p-[30px] bg-white relative z-10">
        <h6 class="text-lg md:text-2xl text-primary font-semibold text-center py-1 mb-2">Logout</h6>
        <p class="px-[14px] text-center cursor-pointer text-gray text-lg">Are you sure want to logout?</p>
        <div class="flex items-center gap-[30px] mt-9">
            <a href="#" class="block px-9 py-4 text-center bg-white text-lg font-semibold text-primary border border-gray w-full" onClick={handleClose}>No</a>
            <button 
                class="block justify-center items-center gap-2.5 px-9 py-4 text-lg font-semibold text-white text-center bg-secondary border border-secondary w-full"
                onClick={handleLogout}>Yes
            </button>
        </div>
    </div>
    </div>
  )
}

export default LogoutPopup