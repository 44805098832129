/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Logo from '../assets/image/logo.webp';
import LoginIcon from '../assets/image/login-icon.svg';
import { login } from '../redux/Slices/loginSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


// Validation schema
const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required*.'),
  password: yup.string()
  .required('Password is required*.')
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  .matches(/\d/, 'Password must contain at least one number')
  .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
});

const SignIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode:"all"
  });

  const onSubmit = async (formData) => {
    try {
      await dispatch(login({ identifier: formData.email, password: formData.password })).unwrap();
      // Reset form after successful login
      reset();
      navigate("/")
      // Show success toast
      toast.success('Login successful');
    } catch (error) {
      // Show error toast
      toast.error('Invalid identifier or password');
    }
  };

  return (
    <div className="flex items-center flex-col justify-center h-screen px-4 md:px-0">
      <div className="max-w-[686px] w-full">
        <div className="flex items-center justify-center">
          <a href="/"><img src={Logo} alt="Logo" /></a>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-[62px] border border-litegray rounded-lg p-[50px]">
            <div className="mb-4">
              <label htmlFor="email" className="block mb-3 text-base font-semibold text-primary">Your email</label>
              <input
                type="email"
                id="email"
                {...register('email')}
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter email"
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
            </div>
            <div>
              <label htmlFor="password" className="block mb-3 text-base font-semibold text-primary">Password</label>
              <input
                type="password"
                id="password"
                {...register('password')}
                className="bg-white border border-litegray text-primary text-base focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter password"
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
            </div>
            {/* <h6 className="text-base text-primary text-right mt-3">Forgot Password?</h6> */}
            <button
              type="submit"
              className="inline-flex justify-center items-center gap-2.5 px-9 py-4 text-lg font-semibold text-white text-center bg-secondary w-full mt-[30px]"
            >
              Log In <img src={LoginIcon} className="me-2" alt="Login Icon" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
