import React, { useState } from 'react';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';

const WebLayout = ({ children }) => {
    const [activeTab, setActiveTab] = useState('0');
    const [filterState, setFilterState] = useState({});

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleFilterChange = (newFilterState) => {
        setFilterState(newFilterState);
    };

    return (
        <div className="flex items-start flex-wrap md:flex-nowrap">
            <Sidebar activeTab={activeTab} handleFilterChange={handleFilterChange} />
            <div className='flex flex-col w-full'>
                <Header activeTab={activeTab} handleTabClick={handleTabClick} />
                <div className=''>
                    {React.Children.map(children, child => {
                        return React.cloneElement(child, { activeTab,filterState });
                    })}
                    {/* {children} */}
                </div>
            </div>
        </div>
    );
}

export default WebLayout;
