import Datafetch from '../../utils/Datafetch';

export default async function GeyPortfoliosDetailsById(id) {
    const query = `query Portfolios($id: ID) {
    portfolio(id: $id) {
        data {
            id
            attributes {
                Title
                Description
                Images {
                    data {
                        id
                        attributes {
                            name
                            alternativeText
                            caption
                            width
                            height
                            formats
                            hash
                            ext
                            mime
                            size
                            url
                            previewUrl
                            provider
                            provider_metadata
                            createdAt
                            updatedAt
                        }
                    }
                }
                PDF {
                    data {
                        id
                        attributes {
                            name
                            alternativeText
                            caption
                            width
                            height
                            formats
                            hash
                            ext
                            mime
                            size
                            url
                            previewUrl
                            provider
                            provider_metadata
                            createdAt
                            updatedAt
                        }
                    }
                }
                portfolio_types {
                    data {
                        id
                        attributes {
                            Name
                            createdAt
                            updatedAt
                            publishedAt
                            portfolios {
                                data {
                                    id
                                    attributes {
                                        Title
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
                industries {
                    data {
                        id
                        attributes {
                            Name
                            createdAt
                            updatedAt
                            publishedAt
                            portfolios {
                                data {
                                    id
                                    attributes {
                                        Title
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
                technologies {
                    data {
                        id
                        attributes {
                            Name
                            createdAt
                            updatedAt
                            publishedAt
                            portfolios {
                                data {
                                    id
                                    attributes {
                                        Title
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
                concepts {
                    data {
                        id
                        attributes {
                            Name
                            createdAt
                            updatedAt
                            publishedAt
                            portfolios {
                                data {
                                    id
                                    attributes {
                                        Title
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
                project_types {
                    data {
                        id
                        attributes {
                            Name
                            createdAt
                            updatedAt
                            publishedAt
                            portfolios {
                                data {
                                    id
                                    attributes {
                                        Title
                                        createdAt
                                        updatedAt
                                        publishedAt
                                    }
                                }
                            }
                        }
                    }
                }
                portfolio_collection {
                    data {
                        id
                        attributes {
                            createdAt
                            updatedAt
                            publishedAt
                        }
                    }
                }
                createdAt
                updatedAt
                publishedAt
                Website
                iOS
                Android
            }
        }
    }
}
`;

    try {
        let storeFetchPara = {
            method: 'POST',
            query,
            variables: { id }
        };

        // if (AccessToken !== undefined) {
        //     let BearerToken = AccessToken;
        //     storeFetchPara = {
        //         method: 'POST',
        //         query,
        //         variables: { id },
        //         BearerToken,
        //     };
        // }

        const { status, body } = await Datafetch(storeFetchPara);

        // Check for HTTP status and response body
        if (status !== 200 || !body || body.errors) {
            if (body?.errors && body?.errors.length > 0) {
                throw new Error(body?.errors[0]?.message);
            }
        }

        if (body?.data !== null) {
            return body?.data;
        }
    } catch (error) {
        console.log(`Error in testDataFetch ${error.message}`);
    }
}
