import Datafetch from "../../utils/Datafetch";

const BearerToken = localStorage.getItem("token");

export default async function updateCollections(
  collectioID,
  portfolioIds,
  AccessToken = BearerToken
) {
  const query = `mutation UpdatePortfolioCollection {
    updatePortfolioCollection(id: ${collectioID}, data: { portfolios:${JSON.stringify(
    portfolioIds
  )}}) {
        data {
            id
            attributes {
                createdAt
                updatedAt
                publishedAt
            }
        }
    }
}
`;

  try {
    let storeFetchPara = {
      method: "POST",
      query,
      //   variables: { id },
    };

    if (AccessToken !== undefined) {
      let BearerToken = AccessToken;
      storeFetchPara = {
        method: "POST",
        query,
        // variables: { id },
        BearerToken,
      };
    }

    const { status, body } = await Datafetch(storeFetchPara);

    // Check for HTTP status and response body
    if (status !== 200 || !body || body.errors) {
      if (body?.errors && body?.errors.length > 0) {
        throw new Error(body?.errors[0]?.message);
      }
    }

    if (body?.data !== null) {
      return body?.data;
    }
  } catch (error) {
    console.log(`Error in testDataFetch ${error.message}`);
  }
}
