import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GeyPortfoliosDetailsById from "../../services/portfolio/getPortfoliosDetailsById";

const initialState = {
    getPortfolioById: {
        data: [],
        loading: false,
        error: null
    }
};


export const fetchPortfolioById = createAsyncThunk(
    "getPortfolioDetail/fetchPortfolioById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await GeyPortfoliosDetailsById(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getPortfolioDetailsSlice = createSlice({
    name: "getPortfoliosDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPortfolioById.pending, (state) => {
                state.getPortfolioById.loading = true;
                state.getPortfolioById.error = null;
            })
            .addCase(fetchPortfolioById.fulfilled, (state, action) => {
                state.getPortfolioById.loading = false;
                state.getPortfolioById.data = action.payload;
            })
            .addCase(fetchPortfolioById.rejected, (state, action) => {
                state.getPortfolioById.loading = false;
                state.getPortfolioById.error = action.payload;
            });
    },
});

export default getPortfolioDetailsSlice.reducer;