import SpinnerImage from "../assets/image/spinning-dots.svg";

const Spinner = () => {
  return (
    <div className="flex justify-center items-center py-4">
      <img src={SpinnerImage} alt="Loading..." width="60" height="60" />
    </div>
  );
};

export default Spinner;
